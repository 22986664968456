// store/modules/headerModule.js
export default {
    namespaced: true, // 确保启用了命名空间
    state: {
        headerColor: 'rgba(0, 0, 0, 0.4)'
    },
    mutations: {
        SET_HEADER_COLOR(state, color) {
            state.headerColor = color;
        }
    },
    actions: {
        updateHeaderColor({ commit }, color) {
            commit('SET_HEADER_COLOR', color);
        }
    }
};
  