// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import header from './modules/header';
import domain from './modules/domain';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    header: header,
    domain: domain
  }
});
