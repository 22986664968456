// services/home.js
import request from '@/request/request';

// 获取轮播图列表
export const getCarouselList = (data) => {
  return request.post('/web/carousel/select', data);
};

// 获取新闻列表
export const getNewsList = (data) => {
  return request.post('/web/news/select', data);
};

// 获取新闻详情
export const getNewsDetails = (data) => {
  return request.post(`/web/news/select`, data);
};

// 查询字典数据列表
export const getDictList = (data) => {
  return request.get(`/system/dict/data/type/${data}`);
};

// 获取合作伙伴列表
export const getPartnerList = (data) => {
  return request.post('/web/partner/select', data);
};

// 获取友情链接列表
export const getFriendshipLinkList = (data) => {
  return request.post('/web/friendshipLink/select', data);
};

// 获取平台信息
export const getPlatformMessage = (data) => {
  return request.post('/web/platformMessage/select', data);
};