// request.js
import axios from 'axios';
import { Message } from 'element-ui'; // 引入 Element UI 的消息提示组件
import errorMessages from './errorMessage'; // 引入自定义的错误消息
import { getToken } from '@/utils/auth'

const request = axios.create({
  //  baseURL: 'http://huihuo.natapp1.cc',
  baseURL: process.env.VUE_APP_BASE_API,
  //baseURL: '//ylapi.lifeok.vip',
  timeout: 5000,
  headers: {
    'Content-Type' : 'multipart/form-data'
  },
});

request.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // config.headers['Authorization'] = `Bearer ${localStorage.getItem('UserToken')}`;
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    if (response.data.code == '200') {
      return response.data;
    } else {
      // 登录过期
      if (response.data.code == '401') {
        Message.error(`登录状态已过期～`);
        // setTimeout(()=> {
        //   localStorage.removeItem('token');
        //   window.router.replace(`/login`);
        // }, 500)
      } else {
        Message.error(response.data.msg);
        // console.log(response.data.msg);
      }
      return Promise.reject(response.data);
    }


  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('请求被取消');
    } else if (error.response) {
      const errorCode = error.response.status;
      if (errorMessages[errorCode]) {
        Message.error(`服务器错误 (${errorCode}): ${errorMessages[errorCode]}`);
      } else if (error.response.data && error.response.data.message) {
        Message.error(`服务器错误 (${errorCode}): ${error.response.data.message}`);
      } else {
        Message.error(`服务器错误 (${errorCode}): 发生意外错误`);
      }
    } else if (error.request) {
      // Message.error('网络错误：请检查您的互联网连接。');
      console.error('网络错误：请检查您的互联网连接。');
    } else {
      Message.error('发生意外错误。');
    }
    return Promise.reject(error);
  }
);

export default request;
