// errorMessages.js
export default  {
    200: '请求成功',
    201: '已创建',
    204: '无内容',
    400: '错误请求',
    401: '未授权访问',
    403: '禁止访问',
    404: '未找到资源',
    500: '服务器内部错误',
    // Add more error codes and messages as needed
};
  