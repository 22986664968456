import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/index'),
    meta: { title: '首页'}
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '@/views/welcome/index'),
    meta: { title: '欢迎您'}
  },
  {
    path: '/',
    component: ()=> import('@/views/layout/index'),
    name: 'Layout',
    children: [
      {  
        path: '/trade',  
        name: 'trade',
        redirect: '/trade/index',
        component: () => import(/* webpackChunkName: "trade" */ '@/views/trade/index'), // 这是贸易服务的主页面，可能是个布局或仪表板  
        meta: { title: '石化产品服务' },  
        children: [
          {  
            path: '',
            name: 'tradeView',  
            component: () => import(/* webpackChunkName: "trade" */ '@/views/trade/trade/index'),  
            meta: { title: '石化产品服务' }  
          },  
          {  
            path: 'login',
            name: 'tradeLogin',  
            component: () => import(/* webpackChunkName: "login" */ '@/views/trade/login/index'),  
            meta: { title: '登录' }  
          },  
          {  
            path: 'declare',  
            name: 'tradeDeclare',  
            component: () => import(/* webpackChunkName: "declare" */ '@/views/trade/declare/index'),  
            meta: { title: '申报详情' }  
          }
        ]  
      },
      {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "news" */ '@/views/news/index'),
        meta: { title: '资讯'}
      },
      {
        path: 'news/:id', // 使用动态路由参数传递新闻ID
        name: 'newsDetail',
        component: () => import(/* webpackChunkName: "news" */ '@/views/news/details/index'),
        meta: { title: '新闻详情' }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/index'),
        meta: { title: '关于我们'}
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {  
  window.scrollTo(0, 0);  
});

export default router
