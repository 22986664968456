import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import '@/assets/css/pages.scss'
import "bootstrap-icons/font/bootstrap-icons.css"
import element from './element'; // 按需引入element ui
import './element/theme/index.css';  // 主题颜色
import { Loading } from 'element-ui';
import store from './store/index.js'
Vue.use(Loading.directive);

for(let key in element){
	Vue.component(key, element[key]);
}
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  //参数配置 可不填
  
  // 懒加载默认加载图片
  // loading: 'xxx.png',
  // 加载失败后加载的图片
  // error: 'xxx.png',
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3 // 尝试加载次数
})

// 日期转换工具
import moment from 'moment';
Vue.prototype.$moment = moment;

import { Message } from 'element-ui'; // 引入 Element UI 的消息提示组件
Vue.prototype.$Message = Message;

Vue.config.productionTip = false

// 注册一个全局自定义指令 `v-only-number-decimal`  限制input 浮点
Vue.directive('only-number-decimal', {
  inserted: function (el) {  
    el.addEventListener('input', function (e) {
      const regex = /^(-?\d+(\.\d{0,2})?)?$/; // 允许最多两位小数  
      if (!regex.test(e.target.value)) {  
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);  
      }  
    });  
  }  
});

// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
// 图片预览组件
import ImagePreview from "@/components/ImagePreview";

Vue.component('ImageUpload', ImageUpload);
Vue.component('ImagePreview', ImagePreview);

import plugins from './plugins' // plugins
Vue.use(plugins);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
