// store/modules/headerModule.js
import { getPlatformMessage } from '@/services/api';
export default {
    namespaced: true, // 确保启用了命名空间
    state: {
        info: {}, // 网站信息
    },
    mutations: {
        SET_INFO(state, data) {
            state.info = data;
        }
    },
    actions: {
        // 获取网站信息
        GetInfo({ commit }) {
            getPlatformMessage().then(res => {
                commit('SET_INFO', res.data);
            });
        }
    }
};
  