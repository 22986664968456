import { Cascader,DatePicker,TimePicker,Button, Form, Input, Select, Option, FormItem, Icon, Row, Col, Empty, Alert, Radio, RadioGroup, RadioButton , Menu ,Submenu, MenuItem, Tabs, TabPane, Pagination, Loading, Upload, Table, TableColumn, Result, Link, Dialog, Tag } from 'element-ui';

export default {
    ElCascader:Cascader,
    ElDatePicker:DatePicker,
    ElTimePicker:TimePicker,
    ElButton:Button,
    ElForm: Form,
    ElFormItem: FormItem,
    ElInput: Input,
    ElSelect: Select,
    ElOption: Option,
    ElIcon: Icon,
    ElRow: Row,
    ElCol: Col,
    ElEmpty: Empty,
    ElAlert: Alert,
    ElRadio: Radio,
    ElRadioGroup: RadioGroup,
    ElRadioButton: RadioButton,
    ElMenu: Menu,
    ElSubmenu: Submenu,
    ElMenuItem: MenuItem,
    ElTabs: Tabs,
    ElTabPane: TabPane,
    ElPagination: Pagination,
    ElLoading: Loading,
    ElUpload: Upload,
    ElTable: Table,
    ElTableColumn: TableColumn,
    ElResult: Result,
    ElLink: Link,
    ElDialog: Dialog,
    ElTag: Tag
}