<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  watch: {
    // 监听路由的变化
    $route: {
      handler(to) {
        this.$nextTick(() => {
          let webTitle = to.meta.title || '南通石油天然气交易平台'
          document.title = `${webTitle}_南通石油天然气交易平台`
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import 'assets/iconfont/iconfont.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
